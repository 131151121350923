// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-activities-page-js": () => import("./../../../src/templates/activities-page.js" /* webpackChunkName: "component---src-templates-activities-page-js" */),
  "component---src-templates-activity-post-en-js": () => import("./../../../src/templates/activity-post.en.js" /* webpackChunkName: "component---src-templates-activity-post-en-js" */),
  "component---src-templates-activity-post-hr-js": () => import("./../../../src/templates/activity-post.hr.js" /* webpackChunkName: "component---src-templates-activity-post-hr-js" */),
  "component---src-templates-conferences-page-js": () => import("./../../../src/templates/conferences-page.js" /* webpackChunkName: "component---src-templates-conferences-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-footer-js": () => import("./../../../src/templates/footer.js" /* webpackChunkName: "component---src-templates-footer-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-lectures-page-js": () => import("./../../../src/templates/lectures-page.js" /* webpackChunkName: "component---src-templates-lectures-page-js" */),
  "component---src-templates-meetings-page-js": () => import("./../../../src/templates/meetings-page.js" /* webpackChunkName: "component---src-templates-meetings-page-js" */),
  "component---src-templates-membership-application-page-js": () => import("./../../../src/templates/membership-application-page.js" /* webpackChunkName: "component---src-templates-membership-application-page-js" */),
  "component---src-templates-membership-page-js": () => import("./../../../src/templates/membership-page.js" /* webpackChunkName: "component---src-templates-membership-page-js" */),
  "component---src-templates-workshops-page-js": () => import("./../../../src/templates/workshops-page.js" /* webpackChunkName: "component---src-templates-workshops-page-js" */)
}

